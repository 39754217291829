/* eslint-disable */
import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import L from 'leaflet';
import { Map, TileLayer, Marker, Polygon, Tooltip, WMSTileLayer} from 'react-leaflet';
import './css/leaflet.css';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import { Modal } from 'react-bootstrap';
import moment from "moment";
import * as WMS from "leaflet.wms";
import CustomWMSLayer from './CustomWMSLayer'

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  let locations: any[] = [];

  let polygonPoints: any[] = [];

  let latlngPoints: any[] = [];

  let heatMapLoc: any[] = [];

  let locCounter: any[] = [];

  let defaultLocation: any[] = [20.598264, -100.528801];

  let defaultPoint: any[] = [48, 10];

  let officialTagList: any[] = [];

  let coordTimeList: any[] = [];

  let centerPoint: any;
  
  const markerIcon = new L.Icon({
    iconUrl: require('css/images/icono_localizacion_app_innogando-01.png'),
    iconSize: [25, 25],
    iconAnchor: [23, 23],
  });
  
  /*Lista de crotales*/
  data.series
    .filter(series => series.refId === 'A')
    .map(series => series.fields.find(field => field.name === 'officialTag'))
    .map(item => {
      if (item) {
        for (var i = 0; i < item.values.length; i++) {
          officialTagList.push(item.values.get(i));
        }
      }
    });
  /*Tiempo en el que se recogio la ultima coordenada del animal*/
  data.series
    .filter(series => series.refId === 'A')
    .map(series => series.fields.find(field => field.name === '_time_location'))
    .map(item => {
      if (item) {
        for (var i = 0; i < item.values.length; i++) {
          let time = moment(item.values.get(i)).format("LLL");
          coordTimeList.push(time);
        }
        console.log(coordTimeList)
      }
    });
  /*Localizaciones junto con el crotal al que pertenecen*/
  data.series
    .filter(series => series.refId === 'A')
    .map(series => series.fields.find(field => field.name === '_value_location'))
    .map(item => {
      if (item) {
        for (var i = 0; i < item.values.length; i++) {
          locations.push({ loc: item.values.get(i).split('::'), tag: '' });
        }
        console.log(locations)
      }
    });
  /*Localizaciones repetidas de los animales para hacer el mapa de calor*/
  data.series
    .filter(series => series.refId === 'C')
    .map(series => series.fields.find(field => field.name === '_value_location'))
    .map(item => {
      if (item) {
        for (var i = 0; i < item.values.length; i++) {
          /*Numero de localizaciones para pintar el mapa de calor*/
          locCounter[i] = (locCounter[item.values.get(i)]||0) + 1;
          
          /*Localizaciones junto con el numero de veces que aparecen para pintar el mapa de calor*/
          let points = item.values.get(i).split('::');
          heatMapLoc.push([points[0], points[1], 0]);
        }
      }
    });

  /*Consulta que devuelve las fincas para pintarlas*/
  data.series
    .filter(series => series.refId === 'B')
    .map(series => series.fields.find(field => field.type === 'number'))
    .map(item => {
      if (item) {
        let points = item!.labels!.points.split('::');
        for (var i = 0; i < points.length; i = i + 2) {
          latlngPoints.push(L.latLng(Number(points[i]), Number(points[i + 1])));
        }
        defaultLocation = [points[0], points[1]];
        polygonPoints.push({fieldPoints: latlngPoints, fieldName: item!.labels!.name});
        latlngPoints = [];
      }
    });

  /*Se vinculan las coordenadas con el numero de apariciones repetidas de las mismas*/
  locCounter.map((item, i) => {
    heatMapLoc[i][2] = item;
  });
  
  /*Se vincula las coordenadas con el momento en el que se obtuvo la localizacion*/
  coordTimeList.map((item, i) => {
    locations[i].time = item;
  });

  /*Se vincula la localizacion con el crotal oficial del animal*/
  officialTagList.map((item, i) => {
    locations[i].tag = item;
  });

  const styles = {
    wrapper: {
      height: '100%',
      width: '100%',
      margin: '0 auto',
      display: 'flex',
    },
    map: {
      flex: 1,
    },
  };

  if(locations.length){
    centerPoint = [locations[locations.length - 1].loc[0], locations[locations.length - 1].loc[1]];
  }
  else if(defaultLocation.length){
    centerPoint = defaultLocation;
  }
  else{
    centerPoint = defaultPoint;
  }

  return (
    <div style={styles.wrapper}>
      {/* Dialogo de error en el caso de que no haya localizaciones */}
      {!heatMapLoc.length && !locations.length && !defaultLocation.length && (
        <Modal.Dialog 
          style={{
            position: 'absolute',
            zIndex: 500,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}>
          <Modal.Header 
            style={{
              width: "40%",
              marginLeft: "auto",
              marginRight: "auto",
              background: "#FFA233",
              paddingTop: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "20%",
            }}>
            <Modal.Title>Ubicación no encontrada en las últimas horas</Modal.Title>
          </Modal.Header>
        </Modal.Dialog>
      )}
      <Map
        style={styles.map}
        center={centerPoint}
        zoom={heatMapLoc.length || defaultLocation.length || locations.length ? 15 : 4}
      >
        <TileLayer
          url={'https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=xJzdoqfho7YmBbcrGPR2'}
        />

        <WMSTileLayer
          url={'https://wms.mapa.gob.es/sigpac/wms?'}
          layers={"recinto"}
          format={'image/png'}
          transparent={true}
          version={'2.0.2'}
          attribution={"Recintos SigPac"}
        />

        {/* <CustomWMSLayer
          layers={['PARCELA']}
          options={{
            opacity: 0.1,
          }}
          url="https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?"
        /> */}

        {locations.map((item: any, i: any) => (
          <Marker
            position={[item.loc[0], item.loc[1]]}
            icon={markerIcon}
            key={i}
            onClick={() =>
                  window.open(
                    'https://www.google.com/maps/search/?api=1&query=' + item.loc[0] + ',' + item.loc[1],
                    '_blank'
                  )
            }
          >
            <Tooltip>{<div>{item.tag}<br/>{item.time}</div> }</Tooltip>
          </Marker>
        ))}

        {polygonPoints.map((item: any, i: any) => (
          <Polygon 
            positions={item.fieldPoints} 
            color="red" 
            key={i}
          > 
          
            <Tooltip>{<div>Información de la finca<br/>Nombre: {item.fieldName}</div> }</Tooltip>
            
          </Polygon>
        ))}

        <HeatmapLayer
          points={heatMapLoc}
          latitudeExtractor={m => m[0]}
          longitudeExtractor={m => m[1]}
          intensityExtractor={m => parseFloat(m[2])}
        />
      </Map>
    </div>
  );
};
